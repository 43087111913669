import { Button, CircularProgress, LinearProgress, Paper, TextField, Typography } from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import axios from "axios";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SbxTableData from "../../components/SbxDisplayData";
import SbxPaperCompanyFolio from "../../components/SbxPaperCompanyFolio";
import SbxSectionContainer from "../../components/SbxSectionContainer";
import SbxTitle from "../../components/SbxTitle";
import { httpConfig, useQueryParams } from "../../utils/general";
import { getCustomerSummary, getSaleSumary } from "../../utils/sumary";

const loadData = debounce((params, callback, callbackError) => {
    axios.get(`/rest/api/v4/customers/customers?ID=${params.CustomerID}&mask_fields=TaxRegimeID`, httpConfig())
        .then(getCustomer => {
            const TaxRegimeID = getCustomer.data.elements[0].TaxRegimeID;
            axios.get(`rest/api/v4/system/general/taxregimecfditaxregimes?TaxRegimeID=${TaxRegimeID}&mask_fields=CFDiTaxRegimeID`, httpConfig())
                .then(resp => {
                    const CFDiTaxRegimeID = resp.data.elements[0].CFDiTaxRegimeID;
                    const gets = [
                        axios.get(`/rest/api/v4/sale/cfdi/summary?saledocument_id=${params.SaleDocumentID}&customer_id=${params.CustomerID}`, httpConfig()),
                        axios.get(`/rest/api/v4/sale/cfdi/cfditaxregimecfdiconcepts?CFDiTaxRegimeID=${CFDiTaxRegimeID}&per_page=-1&mask_fields=CFDiConceptID,CFDiConcept__Code,CFDiConcept__Name`, httpConfig())];
                    Promise.all(gets)
                        .then(responses => callback(responses[0].data, responses[1].data.elements));
                });
        })
        .catch(error => callbackError(error));
}, 100);

const Stamp = () => {
    const navigate = useNavigate();
    const queryParams = useQueryParams();
    const SaleDocumentID = queryParams.get('saledocument_id');
    const CustomerID = queryParams.get('customer_id');
    const folio = queryParams.get('folio');
    const [company, setCompany] = useState(null);
    const [state, setState] = useState(null);
    const [working, setWorking] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleOnNotifyCompanyLoaded = (data) => {
        setCompany(data);
    };

    const handleChange = (e) => {
        if (e.target.name === 'CFDiConcept') {
            const cfdiConceptValue = e.target.value && e.target.value > 0 ? state.CFDiConcepts.find(item => item.ID === e.target.value) : null;
            setState({ ...state, CFDiConceptID: e.target.value, CFDiConcept: cfdiConceptValue });
        }
    };

    const handleError = (error) => {
        setWorking(false);
        const msg = error.response && error.response.data ? error.response.data.error : error.toString();
        setErrorMessage(msg);
    };

    const handleClickExecuteStamp = () => {
        setWorking(true);
        const payload = {
            saledocument_id: SaleDocumentID,
            customer_id: CustomerID,
            cfdiconcept_id: state.CFDiConceptID
        };
        axios.post('/rest/api/v4/sale/cfdi/generatecfdidocument', payload, httpConfig())
            .then(resp => {
                navigate(`/done?company_id=${company.ID}&customer_id=${CustomerID}&saledocument_id=${SaleDocumentID}&cfdidocument_id=${resp.data.ID}&send_mail=true`);
            })
            .catch(error => handleError(error));
    };

    useEffect(() => {
        if (!company)
            return;

        loadData({ SaleDocumentID, CustomerID },
            (summaryData, cfdiConceptData) => {
                const comboData = cfdiConceptData.map((item) => {
                    return { ID: item.CFDiConceptID, Name: `${item.CFDiConcept__Code} - ${item.CFDiConcept__Name}` }
                });
                setState({
                    CFDiConcept: null,
                    CFDiConcepts: [...comboData],
                    SaleSumary: getSaleSumary(summaryData),
                    CustomerSumary: getCustomerSummary(summaryData)
                });
            },
            () => { navigate('/', { replace: true }) }
        );
        // eslint-disable-next-line
    }, [company]);

    if (company && !state)
        return (
            <section>
                <LinearProgress />
            </section>
        );

    return (
        <SbxSectionContainer
            companyData={company}
            onNotifyCompanyLoaded={handleOnNotifyCompanyLoaded}
        >
            {company && state ?
                <div className="d-flex-col f-grow-1" style={{ gap: '8px', maxWidth: '700px' }}>
                    <SbxPaperCompanyFolio company={company} folio={folio} />

                    <Paper elevation={1} style={{ padding: '24px' }}>
                        <div className="d-flex-col" style={{ gap: '16px' }}>
                            <SbxTitle title='Uso del CFDi' />

                            <Autocomplete fullWidth
                                name="CFDiConcept"
                                onChange={(e, option) => handleChange({ target: { name: 'CFDiConcept', value: option ? option.ID : null } })}
                                value={state.CFDiConcept}
                                options={state.CFDiConcepts}
                                getOptionLabel={(option) => `${option ? option.Name : ''}`}
                                getOptionSelected={(option, value) => value && value.ID === option.ID}
                                renderInput={(params) => (<TextField {...params} required variant="outlined" label="Uso del CFDi" />)}
                                renderOption={(option) => (<Typography style={{ color: 'black' }} >{option.Name}</Typography>)}
                            />

                            {errorMessage && (<Alert severity="error" onClose={() => { setErrorMessage(null) }}>{errorMessage}</Alert>)}

                            <div className="d-flex-row" style={{ gap: '8px' }}>
                                <div className="f-grow-1" style={{ minWidth: '200px' }} >
                                    <Button style={{ height: '48px' }}
                                        fullWidth
                                        disabled={working}
                                        variant="contained"
                                        size="large"
                                        onClick={(e) => navigate(-1)}
                                    >Regresar
                                    </Button>
                                </div>
                                <div className="f-grow-1" style={{ minWidth: '200px' }}>
                                    {working ? (
                                        <CircularProgress size={38} />) : (
                                        <Button style={{ height: '48px' }}
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            onClick={handleClickExecuteStamp}
                                            disabled={!state.CFDiConcept}
                                        >Emitir Factura
                                        </Button>)}
                                </div>
                            </div>
                        </div>
                    </Paper>

                    <Paper elevation={1} style={{ padding: '24px' }}>
                        <div className="d-flex-col" style={{ gap: '16px' }}>
                            <SbxTitle title='Datos de la Venta' />
                            <SbxTableData data={state.SaleSumary} />
                        </div>
                    </Paper>

                    <Paper elevation={1} style={{ padding: '24px' }}>
                        <div className="d-flex-col" style={{ gap: '16px' }}>
                            <SbxTitle title='Datos del Cliente' />
                            <SbxTableData data={state.CustomerSumary} />
                        </div>
                    </Paper>

                </div> : null
            }
        </SbxSectionContainer>
    );
};

export default Stamp;