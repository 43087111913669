import { createTheme, ThemeProvider } from '@material-ui/core';
import { Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Companies from './views/Companies';
import DataInput from './views/DataInput';
import Done from './views/Done';
import Header from './views/Header';
import Search from './views/Search';
import Stamp from './views/Stamp';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1195D1'
    },
    secondary: {
      main: '#43a047'
    }
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Header />
        <Routes fromRoute>
          <Route index path="/" element={<Companies />} />
          <Route path="/search" element={<Search />} />
          <Route path="/datainput" element={<DataInput />} />
          <Route path="/stamp" element={<Stamp />} />
          <Route path="/done" element={<Done />} />
          <Route path="*" element={
            <Navigate replace to="/" />
          } />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
