import { LinearProgress } from '@material-ui/core';
import { debounce } from 'lodash';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import CustomCard from './CustomCard';
import './index.css';

const loadData = debounce((callback) => {
    axios.get('/rest/api/v4/sale/cfdi/companies')
        .then(response => {

            const Companies = []

            response.data.forEach(item => {
                if (item.InvoicingPortalEnabled === 1)
                    Companies.push(item)
            });

            callback(Companies)
        });
}, 100);

const Companies = () => {
    const [companies, setCompanies] = useState(null);

    useEffect(() => {
        loadData(data => setCompanies(data));
    }, []);

    if (companies)
        return (
            <section className="section-container">
                <div className="card-container">
                    {
                        companies.map(item => (
                            <CustomCard
                                key={item.ID}
                                data={item}
                            />))
                    }
                </div>
            </section>
        );

    return (
        <section>
            <LinearProgress />
        </section>
    );
};

export default Companies;