import { Link, useScrollTrigger } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ReactComponent as SvgArt00 } from './art00.svg';
import { ReactComponent as SvgArt01 } from './art01.svg';
import { ReactComponent as SvgArt02 } from './art02.svg';
import { getHomePath, useQueryParams } from '../../utils/general.js';
import { useLocation } from 'react-router-dom';
import './index.css';
import axios from 'axios';

const LinkHeader = (props) => (
    <div>
        <Link href={props.href} color="inherit" >
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px' }}>
                <span className="material-icons-outlined small-icon">{props.iconName}</span>
                <span >{props.title}</span>
            </div>
        </Link>
    </div>
);

const DefaultPrivicyPolicies = `Para un mejor servicio ahora tienes 30 días naturales a partir de la fecha de tu consumo para solicitar tu factura, dentro del mismo año en que se realizo tu consumo.`

const Header = (props) => {
    const location = useLocation();
    const showBanner = ['/', '/search'].includes(location.pathname);
    const queryParams = useQueryParams();
    const { window } = props;
    const [privacyPolicies, setPrivacyPolicies] = useState(null);

    const param_company = queryParams.get('company_id');

    useEffect(() => {
        if (!param_company) {
            setPrivacyPolicies('')
            return;
        };

        axios.get(`/rest/api/v4/sale/cfdi/companies?ID=${param_company}`)
            .then(response => {

                const token = { headers: { Authorization: `Bearer ${response.data[0].AccessToken}` } }

                axios.get(`/rest/api/v4/system/settings/configurationglobaloptions?ConfigurationOptionDefinitionID=2230106`, token)
                    .then(resp => {

                        if (resp.data.elements.length > 0) 
                            setPrivacyPolicies(resp.data.elements[0].ValText ? resp.data.elements[0].ValText : DefaultPrivicyPolicies)
                         else 
                            setPrivacyPolicies(DefaultPrivicyPolicies)
                        

                    }).catch(err => {

                        if (err.response.status === 401) 
                            setPrivacyPolicies(DefaultPrivicyPolicies)
                    })
            })
    }, [param_company]);


    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 40
    });

    const topBarClassName = trigger ? 'header-fix-top' : 'header';

    return (
        <>
            <section className="topBar" >
                <Link href="https://www.sbxretail.com" color="inherit" target="_blank" rel="noopener"><span>SBX Soluciones en punto de venta</span></Link>
                <div className="f-grow-1" />
                <div className="centerContent ocultar-div" style={{ gap: '20px' }}>
                    <LinkHeader href="mailto:contact@sbxretail.com" iconName="mail" title="ventas@sbxretail.com" />
                    <LinkHeader href="Tel:3385261871" iconName="phone_iphone" title="33 8526 1871" />
                </div>
            </section>

            {trigger && <section style={{ height: 75 }} />}

            <header className={topBarClassName} >
                <div className="header-div">
                    <div className="d-flex-row f-ai-center" style={{ maxWidth: 180 }}>
                        <a href="/">
                            <img className="header-logo" alt="" src={`${getHomePath()}/images/logo-vertical.png`} />
                        </a>
                    </div>
                    <div className="f-grow-1" />
                    {!showBanner &&
                        <div className="d-flex-row f-ai-center" style={{ justifyContent: 'flex-end', gap: 6 }}>
                            <span className="banner-font-title" style={{ fontSize: '1.5rem' }}>Facturación</span>
                            <span className="banner-font-title banner-font-title-2" style={{ fontSize: '1.5rem' }}>electrónica</span>
                        </div>
                    }
                </div>
            </header>

            {
                showBanner &&
                <section>
                    <div style={{ position: 'relative' }}>
                        <div className="banner-font" style={{
                            textAlign: 'left',
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            flexWrap: 'wrap',
                        }}>
                            <div className="item item-img-bkgd" style={{ minHeight: '220px', width: '35%' }}>
                                <div className="item-content item-content-flex">
                                    <span className="banner-font-title">Facturación</span>
                                    <span className="banner-font-title banner-font-title-2">electrónica</span>
                                </div>
                            </div>

                            <div className="item" style={{ width: '30%' }}>
                                <div className="item-content">
                                    <div className="item-content-shadow">
                                        <span className="material-icons-outlined large-icon">tips_and_updates</span>
                                        <div style={{ marginRight: '10px' }}>
                                            <p style={{whiteSpace: "pre-line"}}>{privacyPolicies}</p>
                                            <p>Recuerda que la fecha de la factura corresponde al día que la generas.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="item">
                                <div className="item-content">
                                    <div className="item-content-shadow">
                                        <span className="material-icons-outlined large-icon">info</span>
                                        <div>
                                            <p className="item-content-title">Instrucciones:</p>
                                            <ul>
                                                <li><span>Debe Contar con :</span></li>
                                                <ol>
                                                    <li><span>RFC</span></li>
                                                    <li><span>Folio único de facturación</span></li>
                                                </ol>
                                                <li><span>Seleccione la marca</span></li>
                                                <li><span>Llenar la información</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <SvgArt00 className="banner-bottom-svg" style={{ opacity: '0.3' }} />
                        <SvgArt01 className="banner-bottom-svg" style={{ opacity: '0.2' }} />
                        <SvgArt02 className="banner-bottom-svg" style={{ opacity: '0.1' }} />
                    </div>

                </section>
            }
        </>
    );
};

export default Header;