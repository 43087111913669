import axios from 'axios';
import { Alert, Autocomplete } from '@material-ui/lab';
import { Button, CircularProgress, LinearProgress, Link, Paper, Switch, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import SbxSectionContainer from "../../components/SbxSectionContainer";
import { httpConfig, useQueryParams } from "../../utils/general";
import '../../styles/general.css';
import SbxPaperCompanyFolio from '../../components/SbxPaperCompanyFolio';
import SbxTitle from '../../components/SbxTitle';

const loadData = debounce(async (rfc, saledocumentID, callback, callbackError) => {
    const gets = [
        axios.get(`/rest/api/v4/customers/customers?TaxCode=${encodeURIComponent(rfc)}&mask_fields=ID,Name,TaxRegimeID,AddressID,AddressUUID,Address__Email1,ZipCode__Number`, httpConfig()),
        axios.get('/rest/api/v4/system/general/taxregimecfditaxregimes?per_page=-1&mask_fields=TaxRegimeID,CFDiTaxRegime__Code,CFDiTaxRegime__Name', httpConfig()),
        axios.get(`/rest/api/v4/sale/saledocuments?ID=${saledocumentID}&mask_fields=StoreID`, httpConfig()),
        axios.get(`/rest/api/v4/system/settings/configurationglobaloptions?ConfigurationOptionDefinitionID=21391,21392,21393,21394,21398,21399,21400,21401,21405,21406,21395,21407&mask_fields=ConfigurationOptionDefinitionID,Val&per_page=-1`, httpConfig()),
    ];
    
    try {
        const responses = await Promise.all(gets);    
        const { StoreID } = responses[2].data.elements[0];
        const resp = await axios.get(`/rest/api/v4/system/settings/configurationstoreoptions?StoreID=${StoreID}&ConfigurationOptionDefinitionID=209,210,211,212,213,214,215,216,217,218,219,220&mask_fields=ConfigurationOptionDefinitionID,Val&per_page=-1`, httpConfig());
        const options = [...new Set([...resp.data.elements, ...responses[3].data.elements])];
        callback(responses[0].data.elements.length > 0 ? responses[0].data.elements[0] : null, responses[1].data.elements, options, StoreID);
    } catch (error) {
        callbackError(error);
    }
}, 100);

const DataInput = () => {
    const navigate = useNavigate();
    const queryParams = useQueryParams();
    const saledocumentID = queryParams.get('saledocument_id');
    const folio = queryParams.get('folio');
    const rfc = queryParams.get('rfc');
    const [company, setCompany] = useState(null);
    const [state, setState] = useState(null);
    const [working, setWorking] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [validity, setValidity] = useState({
        Email: true,
        ZipCode: true
    });

    const handleOnNotifyCompanyLoaded = (data) => {
        setCompany(data);
    };

    const handleChange = (e) => {
        if (e.target.name === 'Accepted')
            setState({ ...state, Accepted: e.target.value });
        else if (e.target.name === 'Name')
            setState({ ...state, Customer: { ...state.Customer, Name: e.target.value.toUpperCase() } });
        else if (e.target.name === 'Email') {
            setState({ ...state, Customer: { ...state.Customer, Address__Email1: e.target.value } });
            const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            setValidity({ ...validity, Email: e.target.value === '' || e.target.value.match(pattern) });
        }
        else if (e.target.name === 'ZipCode') {
            setState({ ...state, Customer: { ...state.Customer, ZipCode__Number: e.target.value } });
            setValidity({ ...validity, ZipCode: e.target.value === '' || e.target.validity.valid });
        }
        else if (e.target.name === 'TaxRegime') {
            const taxRegimeValue = e.target.value && e.target.value > 0 ? state.TaxRegimes.find(item => item.ID === e.target.value) : null;
            setState({ ...state, TaxRegime: taxRegimeValue, Customer: { ...state.Customer, TaxRegimeID: e.target.value } });
        }
    };

    const handleError = (error) => {
        setWorking(false);
        const msg = error.response && error.response.data ? error.response.data.error : error.toString();
        setErrorMessage(msg);
    };

    const handleClickContinue = () => {
        setWorking(true);
        const Address = { Email1: state.Customer.Address__Email1 };
        const ZipCode = { Number: state.Customer.ZipCode__Number };
        const Customer = { Name: state.Customer.Name, TaxCode: rfc, TaxRegimeID: state.Customer.TaxRegimeID };

        if(!state.Customer.TaxRegimeID || state.Customer.TaxRegimeID <= 0) {
            setWorking(false);
            setErrorMessage('Debe seleccionar un regimen fiscal');
            return;
        }

        //4. Ir a la pantalla de selección del timbrado
        const navigateToStamp = () => {
            setWorking(false);
            navigate(`/stamp?company_id=${company.ID}&customer_id=${Customer.ID}&saledocument_id=${saledocumentID}&rfc=${rfc}&folio=${folio}`);
        };

        const getDefaultOption = (storeOptionID, globalOptionID) => {
            const storeFound = state.DefaultOptions.find(item => item.ConfigurationOptionDefinitionID === storeOptionID);

            if (storeFound && (parseInt(storeFound.Val) > 0))
                return parseInt(storeFound.Val);

            const globalFound = state.DefaultOptions.find(item => item.ConfigurationOptionDefinitionID === globalOptionID);

            if (globalFound && (parseInt(globalFound.Val) > 0)) 
                return parseInt(globalFound.Val);

            return null;
        };

        // 3. Se persiste el cliente
        const saveCustomer = () => {
            Customer.AddressID = Address.ID;
            Customer.AddressUUID = Address.UUID;

            if (!state.Customer.ID || state.Customer.ID === 0) {
                Customer.DefaultStoreID = state.DefaultStoreID;
                Customer.FixedCategory101ID = getDefaultOption(209, 21391);
                Customer.FixedCategory102ID = getDefaultOption(210, 21392);
                Customer.FixedCategory103ID = getDefaultOption(211, 21393);
                Customer.FixedCategory104ID = getDefaultOption(212, 21394);
                Customer.FixedCategory105ID = getDefaultOption(213, 21398);
                Customer.FixedCategory106ID = getDefaultOption(214, 21399);
                Customer.FixedCategory107ID = getDefaultOption(215, 21400);
                Customer.FixedCategory108ID = getDefaultOption(216, 21401);
                Customer.FixedCategory109ID = getDefaultOption(217, 21405);
                Customer.FixedCategory110ID = getDefaultOption(218, 21406);
                Customer.PriceListID = getDefaultOption(219, 21395);
                Customer.SalesPersonID = getDefaultOption(220, 21407);

                axios.post(`/rest/api/v4/customers/customers`, Customer, httpConfig())
                    .then(postCustomer => {
                        Customer.ID = postCustomer.data.ID;
                        navigateToStamp();
                    })
                    .catch(error => handleError(error));
            } else {
                Customer.ID = state.Customer.ID;
                axios.put(`/rest/api/v4/customers/customers`, Customer, httpConfig())
                    .then(() => navigateToStamp())
                    .catch(error => handleError(error));
            }
        };

        // 2. Se persiste el Domicilio
        const saveAddress = () => {
            Address.ZipCodeID = ZipCode.ID;
            Address.ZipCodeUUID = ZipCode.UUID;
            if (!state.Customer.AddressID || state.Customer.AddressID === 0) {
                axios.post(`/rest/api/v4/addresses/addresses`, Address, httpConfig())
                    .then(postAddress => {
                        Address.ID = postAddress.data.ID;
                        Address.UUID = postAddress.data.UUID;
                        saveCustomer();
                    })
                    .catch(error => handleError(error));
            } else {
                Address.ID = state.Customer.AddressID;
                Address.UUID = state.Customer.AddressUUID;
                axios.put(`/rest/api/v4/addresses/addresses`, Address, httpConfig())
                    .then(() => saveCustomer())
                    .catch(error => handleError(error));
            }
        };

        // 1. Se pregunta por el codigo postal
        axios.get(`/rest/api/v4/addresses/zipcodes?Number=${ZipCode.Number}&mask_fields=ID,UUID`, httpConfig())
            .then((respZipCode) => {
                if (respZipCode.data.elements.length === 0) {
                    // No existe y es necesario agregarlo
                    axios.post(`/rest/api/v4/addresses/zipcodes`, { Number: ZipCode.Number, Fixed: 0 }, httpConfig())
                        .then(postZipCode => {
                            ZipCode.ID = postZipCode.data.ID;
                            ZipCode.UUID = postZipCode.data.UUID;
                            // 2. Se persiste los datos del domicilio
                            saveAddress();
                        })
                        .catch(error => handleError(error));
                } else {
                    ZipCode.ID = respZipCode.data.elements[0].ID;
                    ZipCode.UUID = respZipCode.data.elements[0].UUID;
                    // 2. Se persiste los datos del domicilio
                    saveAddress();
                }
            })
            .catch(error => handleError(error));
    };

    useEffect(() => {
        if (!company)
            return;

        loadData(rfc, saledocumentID,
            (customerData, taxRegimeData, optionsData, storeID) => {
                const comboData = taxRegimeData.map((item) => {
                    return { ID: item.TaxRegimeID, Name: `${item.CFDiTaxRegime__Code} - ${item.CFDiTaxRegime__Name}` }
                });
                const taxRegimeValue = customerData && customerData.TaxRegimeID ? comboData.find(item => item.ID === customerData.TaxRegimeID) : null;
                setState({
                    Accepted: false,
                    TaxRegime: taxRegimeValue,
                    TaxRegimes: [...comboData],
                    Customer: customerData ? customerData : { ID: 0, Name: '', TaxRegimeID: null, Address__Email1: '', ZipCode__Number: '' },
                    DefaultStoreID: storeID,
                    DefaultOptions: optionsData
                });
            },
            () => { navigate('/', { replace: true }) }
        );
        // eslint-disable-next-line
    }, [company, rfc]);

    if (company && !state)
        return (
            <section>
                <LinearProgress />
            </section>
        );

    return (
        <SbxSectionContainer
            companyData={company}
            onNotifyCompanyLoaded={handleOnNotifyCompanyLoaded}>
            {company && state ?
                <div className="d-flex-col f-grow-1" style={{ gap: '8px', maxWidth: '700px' }}>
                    <SbxPaperCompanyFolio company={company} folio={folio} />

                    <Paper elevation={1} style={{ padding: '24px' }}>
                        <div className="d-flex-col" style={{ gap: '16px' }}>
                            <SbxTitle title='Datos del cliente' />

                            <TextField fullWidth required disabled
                                name="RFC"
                                label="RFC"
                                variant="outlined"
                                value={rfc} />

                            <TextField fullWidth required
                                name="Name"
                                label="Razon social"
                                variant="outlined"
                                onChange={handleChange}
                                value={state.Customer.Name ? state.Customer.Name : ''} />

                            <Autocomplete fullWidth
                                name="TaxRegime"
                                onChange={(e, option) => handleChange({ target: { name: 'TaxRegime', value: option ? option.ID : null } })}
                                value={state.TaxRegime}
                                options={state.TaxRegimes}
                                getOptionLabel={(option) => `${option ? option.Name : ''}`}
                                getOptionSelected={(option, value) => value && value.ID === option.ID}
                                renderInput={(params) => (<TextField {...params} required variant="outlined" label="Regimen fiscal" />)}
                                renderOption={(option) => (<Typography style={{ color: 'black' }} >{option.Name}</Typography>)}
                            />

                            <TextField fullWidth required
                                name="Email"
                                label="Correo electrónico"
                                variant="outlined"
                                value={state.Customer.Address__Email1 ? state.Customer.Address__Email1 : ''}
                                onChange={handleChange}
                                error={!validity.Email}
                                helperText={!validity.Email ? 'Correo inválido !' : ''}
                                inputProps={{ inputMode: 'email' }}
                            />

                            <TextField fullWidth required
                                name="ZipCode"
                                label="Código postal"
                                variant="outlined"
                                onChange={handleChange}
                                value={state.Customer.ZipCode__Number ? state.Customer.ZipCode__Number : ''}
                                error={!validity.ZipCode}
                                helperText={!validity.ZipCode ? 'Código postal inválido !' : ''}
                                inputProps={{ pattern: '[0-9]{5}', maxLength: 5 }}
                            />

                            <div className="d-flex-row">
                                <div className="d-flex-row f-ai-center">
                                    <Link underline="hover" target="_blank" rel="noopener" href="https://www.sbxretail.com/aviso-privacidad.php">
                                        <Typography>Aviso de privacidad</Typography>
                                    </Link>
                                </div>
                                <div className="f-grow-1" />
                                <div className="d-flex-row f-ai-center">
                                    <Typography>Acepto los terminos y condiciones</Typography>
                                    <Switch
                                        name="Accepted"
                                        size="medium"
                                        color="primary"
                                        checked={state.Accepted}
                                        onChange={(e) => handleChange({ target: { name: 'Accepted', value: e.target.checked } })}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </div>
                            </div>

                            {errorMessage && (<Alert severity="error" onClose={() => { setErrorMessage(null) }}>{errorMessage}</Alert>)}

                            <div className="d-flex-row" style={{ gap: '8px' }}>
                                <div className="f-grow-1" style={{ minWidth: '200px' }} >
                                    <Button style={{ height: '48px' }}
                                        fullWidth
                                        disabled={working}
                                        variant="contained"
                                        size="large"
                                        onClick={(e) => navigate(-1)}
                                    >Regresar
                                    </Button>
                                </div>
                                <div className="f-grow-1" style={{ minWidth: '200px' }}>
                                    {working ? (
                                        <CircularProgress size={38} />) : (
                                        <Button style={{ height: '48px' }}
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            onClick={handleClickContinue}
                                            disabled={
                                                !state.Accepted ||
                                                state.Customer.Name === '' ||
                                                !state.Customer.TaxRegimeID ||
                                                state.Customer.TaxRegimeID <= 0 ||
                                                state.Customer.Address__Email1 === '' ||
                                                state.Customer.ZipCode__Number === '' ||
                                                !validity.Email ||
                                                !validity.ZipCode}
                                        >Continuar
                                        </Button>)}
                                </div>
                            </div>
                        </div>
                    </Paper>
                </div> : null
            }
        </SbxSectionContainer>
    );
};

export default DataInput;