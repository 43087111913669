/* eslint-disable no-unused-vars */
import NoPhoto from '../assets/images/icons8-no-photo-100.png';
import Loader from '../assets/images/loader.gif';
import React, { memo, useEffect } from 'react'
import axios from 'axios';

function ImageX(props) {

    const {
        target,
        id,
        alt,
        style = {},
        accessToken
    } = props


    const BASE_URL = `/rest/api/v4/system/general/image?no_cache=true&max_age=5&target=${target}&id=${id}`

    const loadImage =  () => {

        const preview = document.getElementById(`${target}-${id}`)

        const myHeader = {
            headers: { Authorization: `Bearer ${accessToken}` },
            responseType: "arraybuffer",
        };

        axios.get(BASE_URL, myHeader)
            .then(response => {

                if (response.data.byteLength > 0) {
                    const myBlob = new Blob([response.data], { type: 'image/jpeg' });
                    const srcBlob = URL.createObjectURL(myBlob);
                    preview.src = srcBlob;
                }else {
                    preview.src = NoPhoto;
                }
            })
    }

    useEffect(() => loadImage(), [])

    return (
        <>
            <img
                id={`${target}-${id}`}
                loading='lazy'
                alt={alt}
                src={Loader}
                height="60px"
                style={style}
            />
        </>
    )
}

export default memo(ImageX)
