import { Button, CircularProgress, Divider, Paper, TextField, Typography } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SbxSectionContainer from '../../components/SbxSectionContainer';
import { useQueryParams } from '../../utils/general';
import { Alert } from '@material-ui/lab';
import ImageX from '../../components/ImageX';

const Search = () => {
    const navigate = useNavigate();
    const queryParams = useQueryParams();
    const param_rfc = queryParams.get('rfc');
    const param_folio = queryParams.get('folio');
    const [company, setCompany] = useState(null);
    const [working, setWorking] = useState(false);
    const [notification, setNotification] = useState({ open: false, message: '', severity: '' });
    const [validity, setValidity] = useState({
        rfc: true,
        folio: true,
        folioMessage: ''
    });
    const [inputData, setInputData] = useState({
        rfc: '',
        folio: ''
    });

    const handleChange = (e) => {
        setInputData({ ...inputData, [e.target.name]: e.target.value.toUpperCase() });
        setValidity({ ...validity, [e.target.name]: e.target.validity.valid });
    };

    const handleClickValidateFolio = (e) => {

        setWorking(true);

        if (inputData.rfc === 'XAXX010101000' || inputData.rfc === 'XEXX010101000') {
            setNotification({ open: true, message: 'El RFC de público en general no se puede usar para emitir facturas individuales desde el portal', severity: 'error' })
            setValidity({ ...validity, rfc: false })
            setWorking(false)
            return
        }

        const payload = { invoice_kiosk_folio: inputData.folio };
        const header = { headers: { Authorization: `Bearer ${company.AccessToken}` } };
        axios.post('/rest/api/v4/sale/cfdi/validateinvoicekiosk', payload, header)
            .then(resp => {
                setWorking(false);
                const data = resp.data;
                localStorage.setItem('session_access_token', data.access_token);

                if (data.cfdidocument_id && data.cfdifile_id) {
                    // Existe y esta timbrado
                    navigate(`/done?company_id=${company.ID}&customer_id=${data.customer_id}&saledocument_id=${data.saledocument_id}&cfdidocument_id=${data.cfdidocument_id}&cfdifile_id=${data.cfdifile_id}`);
                } else if (data.saledocument_id && (data.saledocument_id > 0)) {
                    // Existe pero no esta timbrado
                    navigate(`/datainput?company_id=${company.ID}&saledocument_id=${data.saledocument_id}&rfc=${encodeURIComponent(inputData.rfc)}&folio=${inputData.folio}`);
                }
                setValidity({ ...validity, folio: false, folioMessage: 'Folio no existe!' });
            })
            .catch(error => {
                setWorking(false);
                const errorMessage = error.response && error.response.data ? error.response.data.error : error.toString();
                setValidity({ ...validity, folio: false, folioMessage: errorMessage });
            });
    };

    const handleOnNotifyCompanyLoaded = (data) => {
        setCompany(data);
    };

    useEffect(() => {
        const newInputData = { rfc: '', folio: '' };

        if (param_rfc && param_rfc !== '')
            newInputData.rfc = param_rfc;

        if (param_folio && param_folio !== '')
            newInputData.folio = param_folio;

        setInputData(newInputData);
        // eslint-disable-next-line
    }, []);

    return (
        <SbxSectionContainer onNotifyCompanyLoaded={handleOnNotifyCompanyLoaded}>
            <Paper elevation={1} style={{
                padding: '12px',
                width: '100%',
                maxWidth: '700px',
            }}>
                {notification.open &&
                    <Alert onClose={() => setNotification({ ...notification, open: false })} severity={notification.severity}>
                        {notification.message}
                    </Alert>
                }
                {company ?
                    <div className="d-flex-row f-ai-center" style={{ alignItems: 'stretch' }}>
                        {/* panel izquierdo */}
                        <div className="d-flex-col f-ai-center" style={{ flexGrow: 1 }}>
                            <div className="d-flex-col" style={{ height: '100%', maxWidth: '268px', gap: 12, padding: '16px' }}>
                                <div className="" style={{ maxHeight: '200px' }}>
                                    <ImageX
                                        target="Company"
                                        id={company.ID}
                                        alt={`Company-${company.ID}`}
                                        style={{ margin: '8px', height: '150px', maxWidth: '200px', objectFit: 'contain' }}
                                        accessToken={company.AccessToken}
                                    />


                                </div>
                                <div style={{ flexGrow: 1 }} />
                                <div >
                                    <Typography variant="h4" style={{ marginTop: '12px', fontWeight: 600 }} >{company.Name}</Typography>
                                </div>
                            </div>
                        </div>

                        {/* panel derecho */}
                        <div className="d-flex-col f-ai-center" style={{ flexGrow: 1 }}>
                            <div className="d-flex-col f-ai-center" style={{ maxWidth: '400px', gap: 12 }}>
                                <div >
                                    <Typography variant="h6" style={{ textAlign: 'left' }}>
                                        Favor de capturar su RFC y el folio único de facturación y presione el boton de "Buscar" para continuar
                                    </Typography>

                                </div>

                                <Divider style={{ margin: '4px 0 4px 0' }} />

                                <TextField
                                    fullWidth
                                    name="rfc"
                                    label="RFC"
                                    value={inputData.rfc}
                                    onChange={handleChange}
                                    error={!validity.rfc}
                                    helperText={validity.rfc ? '' : 'RFC inválido'}
                                    variant="outlined"
                                    inputProps={{ pattern: '[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]' }}
                                />

                                <TextField
                                    fullWidth
                                    name="folio"
                                    label="Folio único de facturación"
                                    value={inputData.folio}
                                    onChange={handleChange}
                                    error={!validity.folio}
                                    helperText={validity.folio ? '' : validity.folioMessage}
                                    variant="outlined"
                                />

                                {working ? (
                                    <CircularProgress size={38} />) : (
                                    <Button style={{ height: '48px' }}
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        onClick={handleClickValidateFolio}
                                        disabled={!validity.rfc || inputData.rfc.length === 0 || inputData.folio.length === 0}
                                    >Buscar
                                    </Button>
                                )}

                            </div>
                        </div>
                    </div> : null
                }
            </Paper>
        </SbxSectionContainer>
    );
};

export default Search;