import { Paper } from '@material-ui/core';
import React from 'react';
import '../styles/general.css';
import ImageX from './ImageX';

const SbxPaperCompanyFolio = (props) => {
    const { company, folio } = props;
    return (
        <Paper elevation={1} style={{ padding: '24px' }}>
            <div className="d-flex-col">
                <div className="d-flex-row" style={{ gap: '8px' }}>
                    <ImageX
                        target="Company"
                        id={company.ID}
                        alt={`Company-${company.ID}`}
                        style={{ margin: '8px', height: '150px', maxWidth: '200px', objectFit: 'contain' }}
                        accessToken={company.AccessToken}
                    />
                    <div className="d-flex-row f-ai-center" ><span className="font-t1" >{company.Name}</span></div>
                    <div className="f-grow-1" />
                    <div><span className="font-l1" >FOLIO:&nbsp;&nbsp;</span><span className="font-c1" >{folio}</span></div>
                </div>
            </div>
        </Paper>
    );
};

export default SbxPaperCompanyFolio;