import { LinearProgress } from "@material-ui/core";
import axios from "axios";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryParams } from "../utils/general";

const loadData = debounce((id, callback) => {
    axios.get(`/rest/api/v4/sale/cfdi/companies?ID=${id}`)
        .then(response => callback(response.data))
        .catch(() => callback(null));
}, 100);

const SbxSectionContainer = (props) => {
    const { children, companyData, onNotifyCompanyLoaded } = props;
    const [company, setCompany] = useState(null);
    const queryParams = useQueryParams();
    const navigate = useNavigate();
    const companyID = queryParams.get('company_id');

    const redirect = (to) => {
        navigate(to, { replace: true });
    };

    useEffect(() => {
        if (companyData) {
            setCompany(companyData);
            return;
        }

        if (companyID)
            loadData(companyID, (data) => {
                if (Array.isArray(data) && data.length > 0) {
                    setCompany(data[0]);
                    if (onNotifyCompanyLoaded)
                        onNotifyCompanyLoaded(data[0]);
                } else
                    redirect('/');
            });
        else
            redirect('/');
        // eslint-disable-next-line
    }, []);

    if (!company)
        return (
            <section>
                <LinearProgress />
            </section>
        );

    return (
        <section>
            <div style={{
                margin: '24px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
            }}>
                {children}
            </div>
        </section>);
};

export default SbxSectionContainer;