import React from 'react';
import '../styles/general.css';

const SbxTitle = ({ title }) => {
    return (
        <div className="d-flex-row f-ai-center" style={{ margin: '12px 0 12px 0' }}>
            <span className="font-t2" >{title}</span>
        </div>
    );
};

export default SbxTitle;