import React from 'react';
import '../styles/general.css';

const SbxTableData = ({ data }) => {
    if (!data)
        return null;

    return (
        <table style={{ textAlign: 'left' }}>
            <tbody>
                {data && data.map((item, ix) =>
                    <tr key={ix}>
                        <th className="font-l1" style={{ minWidth: '80px' }}>{item.Label}</th>
                        <td className="font-c1">{item.Data}</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};

export default SbxTableData;