import { Card, CardActionArea, CardContent, Link, makeStyles, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import ImageX from '../../components/ImageX';

const useStyles = makeStyles(() => ({
    actionArea: {
        minWidth: '200px',
        borderRadius: 16,
        transition: '0.2s',
        '&:hover': {
            transform: 'scale(1.02)',
        },
    },
    card: {
        minWidth: 256,
        borderRadius: 16,
        '&:hover': {
            boxShadow: '0 6px 12px 0 #aaa',
        },
    },
    content: {
        padding: '1rem 1rem 1rem',
    },
    title: {
        fontFamily: 'Open Sans',
        fontSize: '1.5rem',
        fontWeight: 600,
        marginTop: '1rem',
        color: '#222',
    },
}));

const CustomCard = (props) => {
    const { data } = props;
    const classNames = useStyles({ color: '#203f52' });
    // const urlImg = `/rest/api/v4/system/general/image?target=Company&id=${data.ID}&max_age=3600&access_token=${data.AccessToken}`;

    return (
        <Link underline='none' component={RouterLink} to={`/search?company_id=${data.ID}`} >
            <CardActionArea className={classNames.actionArea} value={data.ID} >
                <Card className={classNames.card} elevation={2} >
                    <CardContent className={classNames.content} >
                        <ImageX
                            target="Company"
                            id={data.ID}
                            alt={`Company-${data.ID}`}
                            style={{ margin: '8px', height: '150px', maxWidth: '200px', objectFit: 'contain' }}
                            accessToken={data.AccessToken}
                        />
                        <Typography className={classNames.title} variant={'h4'} >
                            {data.Name}
                        </Typography>
                    </CardContent>
                </Card>
            </CardActionArea>
        </Link>
    );
};



export default CustomCard;