function formatDateTime(value) {
    let date = new Date(value);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
}

function numberFormat() {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
}

export function getSaleSumary(summary) {
    const nf = numberFormat();
    return [
        {
            Label: 'Folio único',
            Data: summary.SaleDocument__InvoiceKioskFolio
        },
        {
            Label: 'Folio nota',
            Data: summary.SaleDocument__Folio
        },
        {
            Label: 'Tienda',
            Data: summary.Store__Name
        },
        {
            Label: 'Fecha y hora',
            Data: formatDateTime(summary.SaleDocument__CreationDate)
        },
        {
            Label: 'Subtotal',
            Data: nf.format(summary.SaleDocument__SubTotal)
        },
        {
            Label: 'IVA',
            Data: nf.format(summary.SaleDocument__Tax1)
        },
        {
            Label: 'IEPS',
            Data: nf.format(summary.SaleDocument__Tax2)
        },
        {
            Label: 'Total',
            Data: nf.format(summary.SaleDocument__Total)
        }
    ]
}

export function getCFDiSummary(summary) {
    const nf = numberFormat();
    return [
        {
            Label: 'Fecha',
            Data: summary.CFDi__Fecha
        },
        {
            Label: 'Total',
            Data: nf.format(summary.CFDi__Total)
        },
        {
            Label: 'Emisor',
            Data: summary.CFDi__Emisor
        },
        {
            Label: 'Receptor',
            Data: summary.CFDi__Receptor
        },
        {
            Label: 'Folio fiscal',
            Data: summary.CFDi__TFD__UUID
        },
        {
            Label: 'Fecha timbrado',
            Data: summary.CFDi__TFD__FechaTimbrado
        },
        {
            Label: 'RFC proveedor cert.',
            Data: summary.CFDi__TFD__RfcProvCertif
        },
        {
            Label: 'Certificado SAT',
            Data: summary.CFDi__TFD__NoCertificadoSAT
        },
        {
            Label: 'Sello SAT',
            Data: summary.CFDi__TFD__SelloSAT
        }
    ]
}

export function getCustomerSummary(summary) {
    return [
        {
            Label: 'RFC',
            Data: summary.Customer__TaxCode
        },
        {
            Label: 'Razón social',
            Data: summary.Customer__Name
        },
        {
            Label: 'Regimén fiscal',
            Data: summary.CFDiTaxRegime__Name
        },
        {
            Label: 'Código postal',
            Data: summary.ZipCode__Number
        },
        {
            Label: 'Correo electrónico',
            Data: summary.Address__Email1
        }
    ]
}